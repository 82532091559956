import breakPoints from './breakPoints';
import colorPalette from './colorPalette';
import sizing from './sizing';
import typography from './typography';
import elevation from './elevations';
import transitions from './transitions';

export const light = {
    ...breakPoints,
    ...sizing,
    ...typography,
    ...elevation,
    ...transitions,
    text01: colorPalette.secondary100,
    text02: colorPalette.secondary300,
    text03: colorPalette.white,
    text04: colorPalette.green100,
    ui01: colorPalette.white,
    ui02: colorPalette.gray100,
    ui03: colorPalette.primary100,
    ui04: colorPalette.primary300,
    ui05: colorPalette.secondary300,
    ui06: colorPalette.orange300,
    ui07: colorPalette.secondary100,
    ui08: colorPalette.gray300,
    boxShadow: '0 21px 43px 0 rgba(123, 123, 123, 0.1)',
    boxShadowStrong: `0 21px 43px 0 rgba(123, 123, 123, 0.34)`
};

export default light;
