import React from 'react';
import { Link } from 'react-router-dom';

import Text from '@/components/Text';
import Button from '@/components/Button';
import Spacer from '@/components/Spacer';
import Section from '@/components/Section';
import InfoBox from '@/components/InfoBox';
import Logotype from '@/components/Logotype';
import { Col, Row } from '@/components/Layout';
import EbookList from '@/views/Ebooks';
import translations from '@/views/Ebooks/constants/translations';
import { DOWNLOAD_URL, SITE_NAME } from '@/constants/globals';

export const Ebooks = () => (
    <Section>
        <Row justifyContent="center">
            <Col number={ 1 }><Link to="/" title={ SITE_NAME }><Logotype/></Link></Col>
            <Spacer size="super-small"/>
            <Spacer size="super-small"/>
            <InfoBox>
                <Text align="center" size="medium">
                    <strong>{ translations.ebooksIntroText01 }</strong>
                </Text>
            </InfoBox>
            <Button as="a" href={ DOWNLOAD_URL }>{ translations.ebooksButtonDownloadAll }</Button>
        </Row>
        <EbookList
            showHeadline={ false }
            paginationStep={ 70 }
        />
    </Section>
);


export default Ebooks;
