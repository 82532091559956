import { EBOOK_TOTAL_EBOOKS } from '@/constants/ebookStats';
import { SITE_CONTACT_EMAIL, SITE_NAME, SITE_SOCIAL_LINK_IG } from '@/constants/globals';

export const translations = {
    faqHeadline: `FAQ`,
    faqList: [
        {
            question: `Ile mam czasu na pobranie e-booków po zakupie ${ SITE_NAME }?`,
            answer: `Po zakupie paczki ${ SITE_NAME } otrzymasz e-mail z dokładnymi instrukcjami dotyczącymi pobierania e-booków. Pamiętaj jednak, że masz tylko 30 dni od daty zakupu, aby pobrać wszystkie e-booki z paczki. Po upływie tego okresu, nie będziesz miał możliwości pobrania plików! Zalecamy pobranie e-booków jak najszybciej po zakupie, aby móc swobodnie z nich korzystać w przyszłości. `,
        },
        {
            question: `Jakie treści znajdę w paczce?`,
            answer: `W paczce znajdują się gotowe jadłospisy, przepisy na pyszne i zdrowe posiłki, poradniki dotyczące psychologii, relacji z partnerem czy poprawy swojej samooceny. Są także e-booki o tym jak trenować siłowo, jak zacząć biegać czy jak pozbyć się bólu pleców. Dodatkowo znajdziesz tam masę wiedzy na temat leczenia anemii, PCOS, insulinooporności czy cukrzycy. Dowiesz się także jak kompleksowo zadbać o swoją skórę czy włosy. To ogrom wiedzy, na wyciągnięcie ręki!`
        },
        {
            question: `O co dokładnie chodzi z paczką ${ SITE_NAME }?`,
            answer: `${ SITE_NAME } to unikalna okazja, w której oferujemy ${ EBOOK_TOTAL_EBOOKS } e-booków zdrowotnych dostępnych w jednej paczce. Pamiętaj, że akcja jest ograniczona czasowo i później nie będzie już dostępna!`
        },
        {
            question: `Skąd pomysł na paczkę ${ SITE_NAME }?`,
            answer: `Od zawsze jesteśmy zdania, że lepiej się łączyć niż dzielić, a we współpracy tkwi siła. Nasz pomysł spodobał się Twórcom Internetowym i dzięki temu teraz możemy zaproponować Wam tak kompleksowy produkt, o ogromnej wartości, w niezwykle przystępnej cenie.`,
        },
        {
            question: `Czy warto kupić HealthyPack?`,
            answer: `${ SITE_NAME } złożony jest z kilkudziesięciu wartościowych e-booków zdrowotnych autorstwa cenionych twórców internetowych. Każdy e-book to efekt minimum kilkumiesięcznej pracy poszczególnego autora. Nawet jeśli zdecydujesz się skorzystać tylko z kilku e-booków z naszej paczki, oszczędzisz znacznie więcej pieniędzy w porównaniu do zakupu każdego e-booka osobno. Dzięki tej akcji stajesz się posiadaczem kompletnej biblioteki zdrowotnej, a paczka jest nie tylko atrakcyjna, ale także opłacalna cenowo!`,
        },
        {
            question: `Dlaczego nie jest dostępna inna forma płatności niż karta?`,
            answer: `Korzystamy z zagranicznych programów sprzedażowych, które niestety nie mają dostępnych polskich form płatności, takich jak BLIK czy przelewy24. Mimo braku tych opcji, chcielibyśmy zapewnić, że płatności kartą są bezpieczne i spełniają najwyższe standardy bezpieczeństwa transakcji online. Dodatkowo można dokonać płatności za pomocą ApplePay.`,
        },
        {
            question: `Czy przysługuje mi prawo do aktualizacji e-booków?`,
            answer: `Nie, prawa do aktualizacji e-booków w ramach ${ SITE_NAME } nie przysługują. Powód tego ograniczenia wynika z charakteru kampanii, która obejmuje sprzedaż i dystrybucję e-booków w określonym czasie. Z racji jednorazowego charakteru promocji oraz atrakcyjnej ceny paczki, niestety nie jesteśmy w stanie zapewnić możliwości aktualizacji treści po zakończeniu kampanii.`,
        },
        {
            question: `Jak mogę skontaktować się w razie pytań lub problemów?`,
            answer: `Jesteśmy dostępni pod adresem  <a href="mailto:${ SITE_CONTACT_EMAIL }?subject=Potrzebuję pomocy z ${ SITE_NAME }">${ SITE_CONTACT_EMAIL }</a> oraz na naszym instagramie ${ SITE_SOCIAL_LINK_IG }. Chętnie odpowiemy na wszystkie pytania i udzielimy niezbędnej pomocy. Czekamy na Ciebie podczas kampanii sprzedaży ${ SITE_NAME } 🥑.`,
        },
        {
            question: `W jakim formacie są zapisane e-booki?`,
            answer: `Wszystkie e-booki są w formacie PDF.`,
        },
        {
            question: `Dlaczego jest ${ EBOOK_TOTAL_EBOOKS + 9} plików?`,
            answer: `Niektórzy Twórcy przekazali jadłospisy w kilku wariantach kalorycznych.`,
        }
    ]
};

export default translations;
