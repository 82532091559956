export const testimonialsList = [
    "Dzięki za kolejną paczkę z mega przydatnymi ebookami!! Na razie przejrzałam je na szybko, ale już widzę, że nie bedę się nudziła tej jesieni.",
    "Kupuję od razu Healthypack, bo ostatnio zwlekałam z paczką podróżniczą i była stresówa przed północą prawie nie zdążyłam.",
    "Dobra już nie mam wymówki żeby nie biegać  Kupiłam paczkę, jest plan rozpisany do biegania, spróbuje tez jeść więcej białka z tymi dwoma e-bookami myślę, że będzie łatwiej. W końcu zacznę działać!",
    "Hej . Wstrzeliłem się z tym healthy paczkiem, sporo tego na jesienne wieczory .Dobry produkt, dzięki, motywuje do pracy nad sobą.",
    "Świetna paczka! Genialne e-booki. Wspaniali Twórcy. To jak zapłacić 219 zł i mieć dostęp do 50 specjalistów. Dietetyczki, Psychodietetyczki, Terapeuci, Trycholożka, Kosmetożka. ŚWIETNA ROBOTA! Polecam każdemu.",
    "Warto zadbać o siebie i zrobić sobie ME TIME! Ta paczka idealnie mi w tym pomaga. Pochłonęłam już 3 e-booki, z każdego wyciągnęłam coś dla siebie. Jak mawia klasyk ”Inwestycja powoli się zwraca” :)",
];

export default testimonialsList;
