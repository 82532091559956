import React from 'react';
import { createBrowserRouter, RouterProvider, } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Normalize from '@/components/Normalize';
import CookieConsent from '@/components/CookieConsent';
import { About, SuccessConfirmation, Ebooks, Faq, Home } from '@/pages';

import { light } from '@/theme';
import { featureFlags, globals, translations } from '@/constants';
import { removeFeatureFlag, setFeatureFlagFromUrl } from '@/utils';

const router = createBrowserRouter( [
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: 'o-healthy-pack',
        element: <About/>,
    },
    {
        path: 'faq',
        element: <Faq/>,
    },
    {
        path: 'e-books',
        element: <Ebooks/>,
    },
    {
        path: 'potwierdzenie-zamowienia',
        element: <SuccessConfirmation
            headline={ translations.confirmationHeadlinePurchase }
        >
            { translations.confirmationContentPurchase }
        </SuccessConfirmation>,
    },
    {
        path: 'subscribe-confirmation',
        element: <SuccessConfirmation
            headline={ translations.confirmationHeadlineSubscribe }
        >
            { translations.confirmationContentSubscribe }
        </SuccessConfirmation>,
    }
] );


function App() {
    const isConsentSet = localStorage.getItem( globals.COOKIE_CONSENT );
    // const isDemoVisible = getFeatureFlag( 'isDemo' ) === 'correct';
    /** ONLY FOR TEST PURPOSE **/
    setFeatureFlagFromUrl( featureFlags.FEATURE_FLAG_SHOW_BUTTON_BUY );

    removeFeatureFlag();
    /** ** **/
    return (
        <ThemeProvider theme={ light }>
            <>
                <Normalize/>
                { !isConsentSet && <CookieConsent/> }
                { <RouterProvider router={ router }/> }
            </>
        </ThemeProvider>
    );
}

export default App;
