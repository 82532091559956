import { EBOOKS_PACK_PRICE, EBOOK_TOTAL_VALUE } from '@/constants/ebookStats';
import { currencies } from '@/constants';

const userLocale = navigator.language || navigator.userLanguage;

export const getBundlePrice = parseInt( EBOOKS_PACK_PRICE ).toLocaleString( userLocale, {
    style: 'currency',
    currency: currencies?.PLN,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} );

export const getRegularPrice = parseInt( EBOOK_TOTAL_VALUE ).toLocaleString( userLocale, {
    style: 'currency',
    currency: currencies?.PLN,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
} );
