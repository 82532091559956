import styled from 'styled-components';


export const BannerImageWrapper = styled.div`
    max-width: 100%;
    position: relative;
    
    padding: 32px;
    img {
        width: 100%;
    }

    @media only screen and (min-width: ${ ( { theme } ) => `${ theme.mobileBreakPoint }px` }) {
       display: none;
    }
`;

export default BannerImageWrapper;
