export const SITE_NAME = `HealthyPack`;
export const SITE_CONTACT_EMAIL = 'pomoc@healthypack.shop';
export const SITE_SOCIAL_LINK_IG = 'https://www.instagram.com/healthypack.shop/';

export const DOWNLOAD_URL = '';
// export const PROMOTION_DATE_END = 1728338340000; // First round
// Second Promo 2024-04-11 23:59:59 +1h (Poland)
export const PROMOTION_DATE_END = 1731279599000; // Second Round
export const PROMOTION_SECOND_ROUND_DATE_START = 1730970000000;


export const COOKIE_CONSENT = 'consentMode';
export const LOCALSTORAGE_DISMISS_REMINDER_SUBSCRIBE = 'dismissReminderSubscribe';
