import React from 'react';

import Main from '@/components/Main';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import StickyCountdown from '@/components/StickyCountdown';

import Steps from '@/views/Steps';
import Banner from '@/views/Banner';
import EbookList from '@/views/Ebooks';
import Authors from '@/views/Authors';
import Summary from '@/views/Summary';
import Advantages from '@/views/Advantages';
import Testimonials from '@/views/Testimonials';

export const Home = () => (
    <>
        <Header/>
        <Main>
            <Banner/>
            <Advantages/>
            <EbookList/>
            <Testimonials/>
            <Steps/>
            <Authors/>
            <Summary/>
        </Main>
        <Footer/>
        <StickyCountdown/>
    </>
);


export default Home;
