export const getColor = ( { theme, variant, isDisabled } ) => {
    let backgroundColor = '';
    let backgroundColorHover = theme.ui06;
    let color = '';
    let colorHover = theme.text03;

    if ( isDisabled ) {
        backgroundColor = theme.ui08;
        color = theme.text03;
    } else if ( variant === 'primary' ) {
        backgroundColor = theme.ui03;
        color = theme.text02;
    } else if ( variant === 'secondary' ) {
        backgroundColor = theme.ui01;
        backgroundColorHover = theme.ui05;
        color = theme.text02;
    } else if ( variant === 'action' ) {
        backgroundColor = theme.ui06;
        backgroundColorHover = theme.ui07;
        color = theme.text03;
    } else if ( variant === 'text' ) {
        backgroundColor = 'none';
        backgroundColorHover = 'none'
        color = theme.text01;
        colorHover = theme.text01;
    }
    return { backgroundColor, color, colorHover, backgroundColorHover };
};

export default getColor;