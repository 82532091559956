import React from 'react';

import { CountdownLabel, CountdownUnit, CountdownUnitWrapper, } from '../styled';

const CountdownItem = ( { number, label, variant } ) => (
    <CountdownUnitWrapper>
        <CountdownLabel variant={ variant }>
            { label }
        </CountdownLabel>
        <CountdownUnit variant={ variant }>{ number }</CountdownUnit>
    </CountdownUnitWrapper>
);

export default CountdownItem;
