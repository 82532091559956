import React, { useState } from 'react';

import {
    PreviewContent,
    PreviewImage,
    PreviewSecondaryText,
    PreviewText,
    PreviewTitle,
    PreviewWrapper
} from '../styled';

import Avatar from '@/components/Avatar';
import { paths } from '@/constants';
import EbookDetails from '@/views/Ebooks/components/EbookDetails';
import Modal from '@/components/Modal';

const Preview = ( { index, avatar, cover, description, title, eBookType, author, pages, price, children, onClick } ) => {
    const profileImgPath = avatar ? `${ paths?.AUTHORS_AVATAR }/${ avatar }` : `${ paths?.ASSETS }/avatar_placeholder.png`;
    const [ isDetailsOpened, setOpenDetails ] = useState( false );
    const handleOpenModal = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'button_click',
            'dataId': 'ebook-button-preview',
            'dataValue': author,
        });
        setOpenDetails( true );
    };

    const handleCloseModal = () => {
        setOpenDetails( false );
    };

    const handleError = (e) => {
        e.target.src = `${ paths?.ASSETS }/ebook_placeholder_cover.jpg`;
    }
    return (
        <>
            <PreviewWrapper onClick={ handleOpenModal }>
                <PreviewImage>
                    <img
                        src={ cover }
                        title={ title }
                        alt={ title }
                        onError={handleError}
                    />
                    { avatar &&
                        <Avatar>
                            <img src={ profileImgPath } alt={ author } title={ author }/>
                        </Avatar> }
                </PreviewImage>
                <PreviewContent>
                    <PreviewTitle>{ eBookType }</PreviewTitle>
                    <PreviewSecondaryText>{ price }</PreviewSecondaryText>
                </PreviewContent>
                <PreviewContent>
                    <PreviewText>{ author }</PreviewText>
                    <PreviewText>{ pages }</PreviewText>
                </PreviewContent>
            </PreviewWrapper>
            <Modal
                open={ isDetailsOpened }
                onClose={ handleCloseModal }
                customStyles={ {
                    modal: { maxWidth: '90%' },
                } }
            >
                <EbookDetails
                    avatar={ profileImgPath }
                    description={description}
                    title={ title }
                    author={ author }
                    cover={ cover }
                    price={ price }
                />
            </Modal>
        </>
    );
};

export default Preview;
