import { ebookStats } from '@/constants';

export const translations = {
    stepsSubHeadline: `PACZKA ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    stepsHeadline: 'Jakie To Bajecznie Proste',

    stepsItemTitle01: 'Opłać Zamówienie',
    stepsItemText01: 'Zapłać używając karty płatniczej lub ApplePay.',

    stepsItemTitle02: `Sprawdź Maila`,
    stepsItemText02: `Otrzymasz dokładną instrukcję na maila.`,

    stepsItemTitle03: `Pobierz E-Booki`,
    stepsItemText03: 'Pobierz swoje e-booki w terminie 30 dni od daty zakupu. ',
};

export default translations;
