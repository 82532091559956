import {EBOOK_TOTAL_EBOOKS} from "@/constants/ebookStats";

export const translations = {
    summaryListHeadline: `W naszych e-bookach znajdziesz:`,
    summaryList: [
        {
            headline: `Smaczne Przepisy i Gotowe Jadłospisy: `,
            text: `Odkryj nowe, pyszne potrawy, które wprowadzą do Twojej diety różnorodność i zdrowie. Skorzystaj również z gotowych jadłospisów, przygotowanych przez topowych dietetyków.`,
        },
        {
            headline: `Poradniki Ćwiczeń: `,
            text: `Dowiedz się, jak skutecznie ćwiczyć, aby osiągnąć swoje cele fitnessowe. Praktyczne wskazówki i plany treningowe dla każdego.`,
        },
        {
            headline: `Zdrowe Nawyki: `,
            text: `Znajdziesz tu także cenne informacje na temat dbania o zdrowie psychiczne, techniki relaksacyjne oraz sposoby radzenia sobie ze stresem.`,
        },
        {
            headline: `Pielęgnacja i Uroda: `,
            text: `Otrzymasz kompleksową wiedzę na temat dbania o skórę i włosy, obejmując porady trychologiczne, skuteczne metody walki z cellulitem oraz sposoby na spowolnienie procesów starzenia.`,
        },
    ],
    summaryHeadline01: `Twoje zdrowie, Twoje samopoczucie, `,
    summaryHeadline02: `Twój wybór!`,
    summaryTextMain01: `${EBOOK_TOTAL_EBOOKS} e-booki, które zmienią Twoje życie na zawsze!`,
    summaryTextMain02: `Bez względu na to, czy marzysz o egzotycznych podróżach, odkrywaniu historycznych miejsc, czy też delektowaniu się lokalnymi smakami, te przewodniki są kluczem do niezapomnianych przygód.`,
    summaryItemText01: 'E-BOOKÓW',
    summaryItemText02: 'STRON',
    summaryItemText03: 'PRZEPISÓW',

};

export default translations;
