import styled from 'styled-components';
import { fadeIn } from '@/styled';

const FeatureBoxWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin: ${ ( { theme } ) => theme.spacing02 } ${ ( { theme } ) => theme.spacing08} 0;
    width: 100%;
    animation: ${ fadeIn } 0.5s ease-out forwards;
    text-decoration: none;
`;

export default FeatureBoxWrapper;
