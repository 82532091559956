import { ebookStats } from '@/constants';

export const translations = {
    advantagesSubHeadline: `PACZKA ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    advantagesHeadline: 'Tysiące Zdrowotnych Korzyści',

    advantagesItemTitle01: 'Mnóstwo Inspiracji',
    advantagesItemText01: `Inwestycja w Twoje zdrowie`,

    advantagesItemTitle02: `Oszczędzasz ${ ebookStats.EBOOK_SAVING }`,
    advantagesItemText02: `Wyjątkowa wartość, w zaskakująco niskiej cenie`,

    advantagesItemTitle03: `${ ebookStats.EBOOK_TOTAL_RECOMMENDATION } rad i wskazówek`,
    advantagesItemText03: 'Twoja droga do zdrowszego stylu życia może zacząć się już teraz',

    advantagesItemTitle04: `${ ebookStats.EBOOK_TOTAL_EBOOKS } e-booków`,
    advantagesItemText04: `Kompletna biblioteczka zdrowotna w zasięgu ręki`,
};

export default translations;
