import { ebookStats } from '@/constants';
import { SITE_NAME } from '@/constants/globals';
export const translations = {
    ebooksHeadline: `Zacznij Zdrowo Żyć`,
    ebooksSubHeadline: `PACZKA ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    ebooksIntroText01: `W tym dokumencie znajduje się 56 e-booków do pobrania. Pamiętaj proszę, że pozostałe e-booki pobierzesz z linku, który znajduje się w mailu. Na pobranie wszystkich e-booków masz 30 dni!`,
    ebooksPages: `str.`,
    ebooksPrice: `Cena regularna: `,
    ebooksButtonShowMore: `ZOBACZ WIĘCEJ`,
    ebooksButtonDownload: `POBIERZ E-BOOK`,
    ebooksButtonDownloadAll: `POBIERZ ${ ebookStats.EBOOK_TOTAL_EBOOKS } E-BOOKÓW`,
    ebookTestimonials: [
        `Gorąco polecam całą paczkę ${SITE_NAME}, taka okazja naprawdę rzadko się trafia!`,
        `Zdecydowanie polecam cały zestaw ${SITE_NAME} To prawdziwa gratka, której okazje nie spotyka się często!`,
        `Zdecydowanie zachęcam do skorzystania z pełnego zestawu ${SITE_NAME} To wyjątkowa okazja, którą napotyka się rzadko.`,
        `To jest SZTOS! Okazja jakiej jeszcze nie było, żeby zgarnąc taki pack!`,
        `Naprawdę polecam ${SITE_NAME} - to absolutna perełka! Rzadko zdarza się taka okazja!`,
        `Jeśli szukasz czegoś wyjątkowego, zdecydowanie polecam zestaw ${SITE_NAME}.`,
        `To prawdziwa okazja! ${SITE_NAME} to absolutny must-have!`,
        `To okazja, której nie można przegapić!`,
        `Z czystym sumieniem polecam zestaw ${SITE_NAME}.`,
        `Jeśli chcesz znaleźć coś naprawdę wartościowego, sięgnij po ${SITE_NAME}. To okazja, której nie spotyka się często!`,
        `${SITE_NAME} to prawdziwy skarb! Gorąco polecam każdemu, kto szuka wyjątkowych okazji!`,
        `Nie mogę się oprzeć, aby nie polecić ${SITE_NAME}. To naprawdę wyjątkowa okazja!`,
        `To naprawdę wyjątkowa okazja! Z całego serca polecam zestaw ${SITE_NAME}.`,
        `${SITE_NAME}! To coś, co naprawdę warto mieć!`,
        `${SITE_NAME} to prawdziwy klejnot. Gorąco polecam każdemu!`,
        `Zdecydowanie polecam zestaw ${SITE_NAME}. To niepowtarzalna okazja, której nie można przegapić!`,
        `Jeśli chcesz zrobić sobie prezent, sięgnij po ${SITE_NAME}. To naprawdę niesamowita okazja!`,
        `Nie mogę się doczekać, aby polecić ${SITE_NAME} wszystkim moim znajomym. To wyjątkowa okazja!`,
        `${SITE_NAME} to coś, co naprawdę warto mieć. Zdecydowanie polecam!`,
        `Polecam zestaw ${SITE_NAME} z całego serca. To okazja, która rzadko się zdarza!`,
        `Jeśli chcesz znaleźć coś naprawdę wyjątkowego, postaw na ${SITE_NAME}!`,
        `Nie mogę się nadziwić, jakie okazje oferuje zestaw ${SITE_NAME}. Zdecydowanie polecam!`,
        `Z czystym sumieniem polecam zestaw ${SITE_NAME}. To prawdziwa perełka!`,
        `Gdzie polecieć? Co można zwiedzić? Te odpowiedzi znajdziesz w ${SITE_NAME}!`,
        `Zdecydowanie polecam cały zestaw ${SITE_NAME}. To niezwykła okazja, którą warto wykorzystać!`,
        `Jeśli szukasz wyjątkowych okazji, zestaw ${SITE_NAME} jest właśnie dla Ciebie. Naprawdę warto!`,
        `Nie mogę się doczekać, aby podzielić się moją radością związana z zestawem ebooków ${SITE_NAME}. Jest to rzecz, którą musisz mieć!`,
        `Nie mogę się doczekać, aby podzielić się moim odkryciem - zestawem ${SITE_NAME}. To prawdziwa perełka!`,
        `Z całego serca polecam zestaw ${SITE_NAME}. To inwestycja, która szybko się zwróci!`,
    ],
};

export default translations;
