import { globals } from '@/constants';
import { getBundlePrice } from '@/utils';
export const translations = {
    navigationEbooks: 'E-BOOKI',
    navigationFAQ: 'FAQ',
    navigationAbout: 'O NAS!',
    navigationTestimonials: 'OPINIE',
    navigationTermsAndConditions: 'Regulamin',
    navigationPrivacy: 'Polityka Prywatności',
    navigationButtonOpen: 'Pokaż menu',
    navigationButtonClose: 'Zamknij menu',
    bannerHeadline01: 'Sięgnij po wiedzę <span>i inspirację</span> do',
    bannerHeadline02: 'zdrowszego życia',
    bannerText01: `Odkryj tajniki zdrowszego życia, które znajdziesz w `,
    bannerText02: `inspirujących e-bookach.`,
    bannerText03: `Paczka e-booków ${globals.SITE_NAME} pozwala zadbać o swoje ciało, poprawić kondycję, dostosować dietę do indywidualnych potrzeb, a także lepiej zrozumieć własne emocje i poprawić jakość relacji.`,
    bannerText04: `Sprawdź zawartość naszej Paczki!`,
    bannerButtonText: 'KUP PACZKĘ',
    commonButtonBuyNowText: 'KUP PACZKĘ TERAZ',
    commonButtonBuyNowTextWithPrice: `KUP PACZKĘ ZA ${getBundlePrice}`,
    pricingHeadlineItem01: globals.SITE_NAME,
    pricingHeadlineItem02: 'Cena Regularna',
    pricingContentBulletPoint01: 'Setki przepisów',
    pricingContentBulletPoint02: `Gotowe jadłospisy`,
    pricingContentBulletPoint03: `Zdrowie psychiczne`,
    pricingContentBulletPoint04: `Plany treningowe`,
    pricingContentBulletPoint05: `Pielęgnacja i uroda`,
    pricingContentBulletPoint06: `Zdrowie mamy i malucha`,
    footerCountdownText: 'Taka okazja się drugi raz nie trafia, Pośpiesz się!',
    footerCountdownTextCompleted: 'Sezon na awokado się skończył.',
    confirmationHeadlinePurchase: 'Potwierdzenie zamównia',
    confirmationContentPurchase: `Dziękujemy za zakup ${globals.SITE_NAME}. Sprawdź swojego maila, aby pobrać e-booki. Miłej lektury! 🥑`,
    confirmationHeadlineSubscribe: 'Potwierdzenie zapisu do newslettera',
    confirmationContentSubscribe: `Dziękujemy za zapisanie się do przypominajki ${globals.SITE_NAME}. Od tej pory nie przegapisz już okazji.`,
};

export default translations;
