export const colorPalette = {
    white: '#ffffff',
    secondary100: '#4a3c2f',
    secondary300: '#4a3c2f',
    gray100: '#faf9f9',
    gray200: '#a4a4a4',
    gray300: '#555555',
    primary100: '#d7bca1',
    primary300: '#d7bca1',
    orange300: '#d48990',
    green100: '#5f682a',
};

export default colorPalette;
