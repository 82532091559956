import React from 'react';

import Text from '@/components/Text';
import Section from '@/components/Section';
import Headline from '@/components/Headline';
import { Col, Row } from '@/components/Layout';

import { globals, paths } from '@/constants';

import translations from '../constants/translations';
import { ContentWrapper, Decor, ImageWrapper } from '../styled';

const AboutUs = () => (
    <Section variant="dark">
        <Row>
            <Col number={ 2 }>
                <ContentWrapper>
                    <Headline>
                        { translations.aboutUsHeadline }
                    </Headline>
                    <Text size="medium">
                        Hej!
                    </Text>
                    <Text size="medium">
                        Jesteśmy czwórką przyjaciół, których łączy wspólna pasja - podróże! Kilka miesięcy temu,
                        stworzyliśmy paczkę podróżniczych e-booków TravelPack.
                    </Text>
                    <Text size="medium">
                        Oprócz wielu pozytywnych opinii o <strong>TravelPack</strong>, bardzo cieszyliśmy się, że dzięki naszej
                        akcji <strong>przekazaliśmy aż 75.000 zł</strong> na rzecz fundacji wspierających poszkodowane zwierzęta.
                    </Text>
                    <Text size="medium">
                        Teraz postanowiliśmy stworzyć kolejną paczkę e-booków, tym razem o tematyce zdrowotnej.
                    </Text>
                    <Text size="medium">
                        Mamy nadzieję, że dzięki niej wiele osób zmieni swoje nawyki żywieniowe,
                        poprawi swoje zdrowie psychiczne i zacznie zwracać uwagę na znaczenie aktywności fizycznej.
                    </Text>
                </ContentWrapper>
            </Col>
            <Col number={ 2 }>
                <ImageWrapper>
                    <img src={ `${ paths.ASSETS }/about_us.jpg` } alt={ globals.SITE_NAME }/>
                    <Decor/>
                </ImageWrapper>
            </Col>
        </Row>
    </Section>
);


export default AboutUs;
